
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import IconQuestion from '@/assets/icons/question.svg'
import IconExample from '@/assets/icons/airtable.svg'
import FilterCondition from '@/components/Viewer/TableData/FilterCondition.vue'
import Store from '@/store/modules/Store'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import setTableViewConfig from '@/controllers/forgeViewer/setTableViewConfig'
import { ViewerModel } from '@/store/Models'

@Component({
  components: { FilterCondition, IconQuestion, IconExample },
  directives: { onClickaway }
})
export default class FilterPopup extends Vue {
  @Prop() model!: ViewerModel

  private showFilterOptions: boolean = false
  private conditionsList: any[] = []

  async mounted() {
    this.$root.$on('show-filter', () => {
      this.showFilterOptions = true
    })

    this.$root.$on('set-filters', filters => {
      if (filters) {
        this.conditionsList = filters.filters
        this.setFilters(0)
      } else {
        this.conditionsList = Store.filterConditions
      }
    })

    this.$root.$on('set-filters-table-view', () => {
      this.conditionsList = this.filterConditions
      this.setFilters(0)
    })
  }

  private get filterConditions(): any[] {
    return Store.filterConditions
  }

  private get activeHeaders() {
    return Store.activeHeaders
  }

  private get activeRows() {
    return Store.activeRows
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private close() {
    this.showFilterOptions = false
  }
  F

  private addCondition() {
    if (!this.conditionsList.length) {
      const defaultConditions = {
        field: this.activeHeaders[0].header,
        operator: this.$t('filter.operators.contains') as string,
        value: ''
      }

      this.conditionsList.push(defaultConditions)

      if (Store.activeTableView !== null) {
        this.tableViews[Store.activeTableView].config.filters.push(
          defaultConditions
        )
        this.setFilters(0)
      } else {
        const modelConfig = Store.assetForViewer.configs.find(
          c => c.modelName === this.model.objectKey
        )

        if (modelConfig && modelConfig.filters) {
          let filters = modelConfig.filters.find(
            // @ts-ignore
            f => f.tableName === this.tableNameTitle
          )

          const payload = {
            tableName: this.selectedTableName,
            filters: this.conditionsList
          }

          if (filters) {
            // @ts-ignore
            filters = payload
          } else {
            // @ts-ignore
            modelConfig.filters.push(payload)
          }
        }

        Store.setFilterConditions(this.conditionsList)
      }
    }
  }

  private async deleteCondition(index: number) {
    if (Store.activeTableView !== null) {
      this.tableViews[Store.activeTableView].config.filters = []
      this.conditionsList = []

      Store.resetFilterConditions()
      Store.resetFilterList()

      const title = await this.tableViews[Store.activeTableView].title

      await setTableViewConfig(
        'filters',
        this.selectedTableName,
        this.model.objectKey,
        title,
        {
          filters: []
        }
      )
    } else {
      if (this.conditionsList.length === 1) {
        this.conditionsList = []

        Store.resetFilterConditions()
        Store.resetFilterList()
      } else {
        this.conditionsList = this.conditionsList.filter((condition, i) => {
          if (i !== index) {
            Store.setFilterConditions(this.conditionsList)
          }
        })
      }

      const modelConfig = Store.assetForViewer.configs.find(
        c => c.modelName === this.model.objectKey
      )
      if (modelConfig && modelConfig.filters) {
        let filterIndex = modelConfig.filters.findIndex(
          // @ts-ignore
          f => f.tableName === this.selectedTableName
        )

        // Delete object filters

        modelConfig.filters.splice(index, 1)

        await setModelConfig('remove-filter', this.model.objectKey, {
          filters: {
            tableName: this.selectedTableName,
            filters: modelConfig.filters
          }
        })
      }
    }
  }

  private setFilterTable() {
    if (this.conditionsList.length) {
      const rows = this.activeRows

      const indexesSet: Set<number>[] = []

      for (const condition of this.conditionsList) {
        indexesSet.push(new Set())

        const { field, operator, value } = condition

        if (condition.value !== '') {
          const headerIndex =
            field === 'name'
              ? 0
              : this.activeHeaders.findIndex(h => h.header === field)

          const checkNumber = (value: any) => {
            const regex = new RegExp(/\d+/)
            return regex.test(value)
          }

          switch (operator) {
            case this.$i18n.t('filter.operators.contains') as string:
              rows.forEach((row, index) => {
                if (checkNumber(value)) {
                  if (typeof row[headerIndex] === 'number') {
                    if (row[headerIndex].toString().includes(value)) {
                      indexesSet[indexesSet.length - 1].add(index)
                    }
                  } else {
                    if (row[headerIndex].includes(value)) {
                      indexesSet[indexesSet.length - 1].add(index)
                    }
                  }
                } else if (
                  !checkNumber(value) &&
                  row[headerIndex].toLowerCase().includes(value.toLowerCase())
                ) {
                  indexesSet[indexesSet.length - 1].add(index)
                }
              })
              break
            case this.$t('filter.operators.doesNotContain') as string:
              rows.forEach((row, index) => {
                if (checkNumber(value)) {
                  if (typeof row[headerIndex] === 'number') {
                    if (!row[headerIndex].toString().includes(value)) {
                      indexesSet[indexesSet.length - 1].add(index)
                    }
                  } else {
                    if (!row[headerIndex].includes(value)) {
                      indexesSet[indexesSet.length - 1].add(index)
                    }
                  }
                } else if (
                  !checkNumber(value) &&
                  !row[headerIndex].toLowerCase().includes(value).toLowerCase()
                ) {
                  indexesSet[indexesSet.length - 1].add(index)
                }
              })
              break
            case this.$i18n.t('filter.operators.is') as string:
              rows.forEach((row, index) => {
                if (checkNumber(value)) {
                  if (row[headerIndex] === value) {
                    indexesSet[indexesSet.length - 1].add(index)
                  }
                } else if (
                  !checkNumber(value) &&
                  row[headerIndex].toLowerCase() === value.toLowerCase()
                ) {
                  indexesSet[indexesSet.length - 1].add(index)
                }
              })
              break
            case this.$i18n.t('filter.operators.isNot') as string:
              rows.forEach((row, index) => {
                if (checkNumber(value)) {
                  if (row[headerIndex] !== value) {
                    indexesSet[indexesSet.length - 1].add(index)
                  }
                } else if (
                  !checkNumber(value) &&
                  row[headerIndex].toLowerCase() !== value.toLowerCase()
                ) {
                  indexesSet[indexesSet.length - 1].add(index)
                }
              })
              break
            case this.$i18n.t('filter.operators.isEmpty') as string:
              rows.forEach((row, index) => {
                if (row[headerIndex] === '') {
                  indexesSet[indexesSet.length - 1].add(index)
                }
              })
              break
            case this.$i18n.t('filter.operators.isNotEmpty') as string:
              rows.forEach((row, index) => {
                if (row[headerIndex] !== '') {
                  indexesSet[indexesSet.length - 1].add(index)
                }
              })
              break
          }
        }

        const defIndexesArr = indexesSet.map(iSet => Array.from(iSet))

        const defIndexesRaw = []

        if (defIndexesArr[0].length) {
          defIndexesArr[0].forEach(index => {
            if (defIndexesArr.every(i => i.includes(index))) {
              defIndexesRaw.push(index)
            }
          })
        }
        Store.setFilterList(defIndexesRaw)
      }
    }
  }

  private setHeader(payload: any[], index: number) {
    this.conditionsList[index].field = payload[0].header
    this.setFilters(index)
  }

  private setOperator(payload: any[], index: number) {
    this.conditionsList[index].operator = payload[0]
    this.setFilters(index)
  }

  private setValue(value: string, index: number) {
    this.conditionsList[index].value = value
    this.setFilters(index)
  }

  private setFilters(index: number) {
    if (
      this.conditionsList[index].field &&
      this.conditionsList[index].operator
    ) {
      this.setFilterTable()
    }
  }

  @Watch('conditionsList', { deep: true })
  async onConditionsListChange() {
    if (this.conditionsList.length && Store.activeTableView === null) {
      await setModelConfig('filters', this.model.objectKey, {
        filters: {
          tableName: this.selectedTableName,
          filters: this.conditionsList
        }
      })
      Store.setFilterConditions(this.conditionsList)
    } else if (this.conditionsList.length && Store.activeTableView !== null) {
      const title = await this.tableViews[Store.activeTableView].title
      const filters = this.conditionsList

      if (filters) {
        await setTableViewConfig(
          'filters',
          this.selectedTableName,
          this.model.objectKey,
          title,
          {
            filters: filters
          }
        )
        Store.setFilterConditions(this.conditionsList)
      }
    }
  }

  @Watch('filterConditions')
  onFilterConditionsChange() {
    if (this.filterConditions.length) {
      this.conditionsList = this.filterConditions
    } else {
      this.conditionsList = []
    }
  }
}
