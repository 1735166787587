
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import Dropdown from '@/components/shared/Dropdown.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import Store from '@/store/modules/Store'
import { ViewerModel } from '@/store/Models'
import getIdsFromDashboard from '@/controllers/cumulio/getIdsFromDashboard'
import Toggler from '@/components/shared/Toggler.vue'

@Component({
  components: { Dropdown, IconSpinner, Toggler }
})
export default class CumulioPanel extends Vue {
  @Prop() models!: ViewerModel[]
  @Prop() selectionCumulioMode!: string

  private clicking: boolean = false
  private widthRate: number = 320
  private position
  private dashboardId: string = ''
  private dashboards = []
  private loading: boolean = true
  private recentlyChanged: boolean = false
  private autoSelectDashboard: string = ''
  private mainModelName: string = ''
  private selectMode: boolean = false
  private colorInactive: boolean = false
  private showSettings: boolean = false
  private preSelected: any = null
  private mainColumnColorId = ''

  private ssoToken: {
    id: string
    token: string
  } = {
    id: '',
    token: ''
  }

  private async getToken() {
    return localStorage.getItem(process.env.VUE_APP_LUZMO_COOKIE)
      ? JSON.parse(localStorage.getItem(process.env.VUE_APP_LUZMO_COOKIE))
      : await this.getAuth()
  }

  private async getAuth() {
    try {
      const fromAnalytics = false
      const modelNames = this.models.map(m => m.objectKey)

      const {
        data: { getCumulioSsoToken }
      } = await this.$apollo.query({
        variables: { fromAnalytics, modelNames },
        query: require('@/graphql/queries/get-cumulio-sso-token.graphql')
      })

      if (getCumulioSsoToken) {
        localStorage.setItem(
          process.env.VUE_APP_LUZMO_COOKIE,
          getCumulioSsoToken
        )
        return JSON.parse(getCumulioSsoToken)
      }
    } catch {
      localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
      this.$toasted.error(this.$i18n.t('errors.ssoToken') as string)
    }
  }

  async mounted() {
    this.getActiveModel()

    let { token } = await this.getToken()

    if (token === null) {
      localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
      token = await this.getToken()
    }

    this.ssoToken = token
    this.handleResizer()

    await this.setDashboards()

    document.addEventListener('update-column-color', (event: CustomEvent) => {
      this.mainColumnColorId = event.detail.columnId
    })
  }

  private handleResizer() {
    const resizer = document.getElementById('resizer')

    resizer.addEventListener(
      'mousedown',
      ({ x }) => {
        this.clicking = true
        this.position = x

        document.addEventListener('mousemove', this.resize, false)
      },
      false
    )

    document.addEventListener('mouseup', () => {
      this.clicking = false
      document.removeEventListener('mousemove', this.resize, false)
      this.$root.$emit('resize', 0, this.clicking)
    })
  }

  private async setDashboards() {
    try {
      const assetId = Store.assetForViewer.id

      const {
        data: { relatedCumulioDashboards }
      } = await this.$apollo.query({
        variables: { assetId },
        query: require('@/graphql/queries/related-cumulio-dashboards.graphql')
      })

      if (relatedCumulioDashboards.dashboardId) {
        this.autoSelectDashboard = relatedCumulioDashboards.dashboardId

        this.getDashboards()
      } else {
        this.getDashboards()
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.standard') as string)
    }
  }

  private resize(event) {
    if (this.clicking) {
      this.widthRate += -this.position + event.x
      this.position = event.x

      this.$root.$emit('resize', this.position, this.clicking)
    }
  }

  private getDashboards() {
    const cumulio: any = this.$refs.cumulio as any

    //@ts-ignore
    cumulio.getAccessibleDashboards().then(dashboards => {
      if (this.autoSelectDashboard) {
        this.dashboardId = this.autoSelectDashboard

        this.preSelected = dashboards.find(d => d.id === this.dashboardId)

        if (this.preSelected) {
          const dashboardIndex = dashboards.findIndex(
            d => d.id === this.dashboardId
          )

          this.dashboards = dashboards

          this.dashboards.splice(dashboardIndex, 1)

          this.dashboards.unshift(this.preSelected)
        } else {
          this.dashboards = dashboards.sort((a, b) =>
            a.name > b.name ? 1 : -1
          )
        }
      } else {
        this.dashboards = dashboards.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
    })

    this.loading = false
  }

  private getActiveModel() {
    if (this.models.length > 1) {
      const model = this.models.find(m => m.isMain)

      if (model) {
        this.mainModelName = model ? model.objectKey : this.models[0].objectKey
      }
    } else {
      this.mainModelName = this.models[0].objectKey
    }
  }

  private handleSelection(selection: string) {
    const { id } = this.dashboards.find(d => d.name === selection)
    this.dashboardId = id
  }

  private async changedFilters(payload) {
    try {
      this.isSelecting()

      const idsGroups = await getIdsFromDashboard(
        payload.data.filters,
        this.models,
        payload.data.changed,
        this.selectionCumulioMode,
        this.mainColumnColorId
      )

      idsGroups ? this.selectIds(idsGroups) : this.nonSelecting()
    } catch (error) {
      if (error.message === 'error-id-from-dashboard') {
        this.nonSelecting()
      }
    }
  }

  private timedUpdate() {
    const dashboardId = this.dashboardId

    // todo refactor
    setTimeout(() => {
      if (dashboardId === this.dashboardId) this.handleLastDashboardOpened()
    }, 2 * 1000)
  }

  private async handleLastDashboardOpened() {
    try {
      const dashboardId = this.dashboardId
      const assetId = Store.assetForViewer.id

      await this.$apollo.mutate({
        variables: { dashboardId, assetId },
        mutation: require('@/graphql/mutations/update-related-dashboard.graphql')
      })
    } catch {
      this.$toasted.error(this.$i18n.t('errors.standard') as string)
    }
  }

  @Watch('widthRate')
  onWidthRateChange() {
    if (this.widthRate < 320) {
      this.widthRate = 320
    }
  }

  @Watch('dashboardId')
  onDashboardIdChange() {
    this.recentlyChanged = true
    this.timedUpdate()
  }

  @Emit()
  selectIds(idsGroup: any): any {
    return idsGroup
  }

  @Emit() isSelecting() {}
  @Emit() nonSelecting() {}

  @Emit()
  setSelectionMode() {
    this.selectMode = !this.selectMode
    return this.selectMode
  }

  @Emit()
  setColorInactive() {
    this.colorInactive = !this.colorInactive
    return this.colorInactive
  }

  beforeDestroy() {
    localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
  }
}
