
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import IconBell from '@/assets/icons/bell.svg'
import { directive as onClickaway } from 'vue-clickaway'
import UpdateNotification from '@/components/Dashboard/Navbar/Notifications/UpdateNotification.vue'
import SyncNotification from '@/components/Dashboard/Navbar/Notifications/SyncNotification.vue'
import Store from '@/store/modules/Store'
import DeleteNotification from '@/components/Dashboard/Navbar/Notifications/DeleteNotification.vue'
import SharedAssetNotification from '@/components/Dashboard/Navbar/Notifications/SharedAssetNotification.vue'
import XlsxMassiveNotification from '@/components/Dashboard/Navbar/Notifications/XlsxMassiveNotification.vue'
import IconTrash from '@/assets/icons/trash-sm.svg'
import DeleteWarning from '@/components/Dashboard/Assets/Modals/DeleteWarning.vue'

@Component({
  apollo: {
    userNotifications: {
      query: require('@/graphql/queries/user-notifications.graphql'),
      result({ data: { userNotifications } }) {
        this.checkFirstNotification(userNotifications[0])

        if (userNotifications) {
          Store.setNotifications(userNotifications.filter(n => !n.seen))
          Store.setNotificationsRead(userNotifications.filter(n => n.seen))
        }
      }
    }
  },
  components: { DeleteWarning, IconBell, IconTrash },
  directives: { onClickaway }
})
export default class Notifications extends Vue {
  @Prop({ default: true }) fromDashboard!: boolean

  private show: boolean = false
  private interval: any
  private tab: 'unread' | 'read' = 'unread'
  private bounce: boolean = false
  private showConfirmationPopup: boolean = false

  private get notifications(): any[] {
    return Store.notifications
  }

  private get notificationsRead(): any[] {
    return Store.notificationsRead
  }

  mounted() {
    // todo: ANULAR REFETCH - SUSTITUIR POR PATRÓN GQL-SUBSCRIPTION
    this.interval = setInterval(() => {
      this.$apollo.queries.userNotifications.refetch()
    }, 10 * 1000)
  }

  private handleClose() {
    if (this.show) {
      this.show = false
    }

    this.showConfirmationPopup = false
  }

  private setComponent(type: string): Vue.Component | null {
    return type === 'update'
      ? UpdateNotification
      : type === 'sync' ||
        type === 'update-xlsx' ||
        type === 'stored-metadata' ||
        type === 'sync-in-progress'
      ? SyncNotification
      : type === 'analytics-delete' ||
        type === 'model-delete' ||
        type === 'asset-delete' ||
        type === 'hub-delete'
      ? DeleteNotification
      : type === 'shared-asset'
      ? SharedAssetNotification
      : type === 'xlsx-massive'
      ? XlsxMassiveNotification
      : null
  }

  private get notificationsLength(): number {
    return this.notifications.length + this.notificationsRead.length
  }

  private hiddenConfirmationPopup() {
    this.showConfirmationPopup = false
  }

  private async deleteNotifications() {
    this.showConfirmationPopup = false
    const seen = this.tab === 'read'

    try {
      const {
        data: { deleteNotifications }
      } = await this.$apollo.mutate({
        variables: { seen },
        mutation: require('@/graphql/mutations/delete-notifications.graphql')
      })

      if (deleteNotifications) {
        this.$toasted.success(
          this.$i18n.t('success.deleteNotifications') as string
        )
        if (seen) {
          Store.setNotificationsRead([])
        } else {
          Store.setNotifications([])
        }
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.deleteNotifications') as string)
    }
  }

  async checkFirstNotification(notification) {
    if (!notification) return

    const check =
      notification.type === 'xlsx-massive' &&
      !notification.given &&
      !notification.seen

    if (check) {
      this.$toasted.success(this.$i18n.t('updatedDataMassive') as string)

      const { notificationId } = notification

      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/set-notification-given.graphql'),
        variables: { notificationId }
      })
    }
  }

  @Watch('notificationsLength')
  async onNotificationsChange() {
    this.bounce = true

    setTimeout(() => (this.bounce = false), 3000)
  }

  @Watch('show')
  onShowChange() {
    if (!this.show) {
      this.showConfirmationPopup = false
    }
  }

  beforeDestroy() {
    clearInterval(this.interval)
  }
}
