import setFiltersData from './setFiltersData'
import getMainColumnColorId from '@/controllers/cumulio/getMainColumnColorId'

export default async (
  filters: any[],
  models: any[],
  changed: any,
  mode: string,
  mainColumnColorId: string
) => {
  try {
    const modelNames = models.map(m => m.objectKey)

    if (!filters.length) return filters

    const data = await setFiltersData(filters)

    let colorReference = null

    const assignColorColumn = (columnId: string) => {
      const filterFound = filters.find(
        f => f.parameters[0].column_id === columnId
      )

      if (filterFound) {
        colorReference = filterFound.parameters[1]
      }
    }

    if (!mainColumnColorId) {
      const { datasetId } = filters[0].parameters[0]

      const columnId = await getMainColumnColorId(datasetId)

      if (columnId) {
        new CustomEvent('update-column-color', { detail: { columnId } })

        assignColorColumn(columnId)
      }
    }

    if (mainColumnColorId) {
      assignColorColumn(mainColumnColorId)
    }

    if (!colorReference) {
      if (mode === 'color' && changed.length) {
        colorReference = changed[0].parameters[1]
      }
    }

    if (data) {
      let ids = {}

      data.forEach(d => {
        const viewerId = d[0]
        const modelName = d[1].id
        let color = ''

        if (mode === 'color' && colorReference) {
          const foundReference = d.find(
            dt =>
              (typeof dt === 'object' && dt.id === colorReference) ||
              (typeof dt === 'object' &&
                Array.isArray(colorReference) &&
                colorReference.includes(dt.id))
          )

          if (foundReference && foundReference.color) {
            color = foundReference.color
          } else {
            const colorFound = d.find(dt => typeof dt === 'object' && dt.color)

            if (colorFound) {
              color = colorFound.color
            }
          }
        }

        if (modelNames.includes(modelName)) {
          if (ids[modelName] === undefined) {
            ids = { ...ids, [modelName]: { [viewerId]: color } }
          } else {
            ids[modelName] = { ...ids[modelName], [viewerId]: color }
          }
        }
      })

      return ids
    }
  } catch (error) {
    console.error('error', error)
  }
}
