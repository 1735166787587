
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconXlsx from '@/assets/icons/file-excel.svg'
import { read } from 'xlsx'
import Dropdown from '@/components/shared/Dropdown.vue'
import MapFields from '@/components/shared/MapFields.vue'
import Store from '@/store/modules/Store'
import Button from '@/components/shared/Button.vue'
import ImportOverwriteXlxs from '@/components/Viewer/TableData/ImportOverwriteXlsx.vue'
import IconUnselected from '@/assets/icons/square.svg'
import IconSelected from '@/assets/icons/square-check.svg'
import getSheetGroups from '@/controllers/dataManagement/get-sheet-groups'

@Component({
  components: {
    IconSelected,
    IconUnselected,
    ImportOverwriteXlxs,
    Button,
    MapFields,
    Dropdown,
    IconSpinner,
    IconXlsx
  },
  directives: { onClickaway }
})
export default class ConnectionModal extends Vue {
  @Prop() tableName!: string

  private uploadedBook: any = null
  private sheetNames: any[] = []
  private selectedSheet: string = ''
  private fields: any[] = []
  private selectedFields: boolean[] = []
  private mapFieldIndex: number = 0
  private destFieldIndex: number = 0
  private addedHeaders: {
    header: string
    origin: string
    selected: boolean
  }[] = []
  private headersWereAdded: boolean = false
  private matchingHeaders: boolean = null
  private tableXlsxHeadersNames: string[] = []
  private loading: boolean = false

  private get headers() {
    // if (
    //   !Store.activeHeaders.includes({ header: 'viewerId' }) &&
    //   !Store.activeHeaders.includes({
    //     header: 'externalId'
    //   })
    // ) {
    //
    // }

    return Store.activeHeaders.map(h => h.header)
  }

  private openBrowser() {
    document.getElementById('input').click()
  }

  private upload(file: File) {
    this.loading = true

    const reader = new FileReader()

    reader.onload = ({ target: { result } }) => {
      this.uploadedBook = read(result, { type: 'binary' })
    }

    reader.readAsBinaryString(file)
  }

  private setHeaders() {
    const selectedSheet = this.uploadedBook.Sheets[this.selectedSheet]

    const fields: any[] = []

    const fieldRegex = new RegExp(/[A-Z]+1$/)

    const fieldsRefs = Object.keys(selectedSheet).filter(f =>
      fieldRegex.test(f)
    )

    for (const ref of fieldsRefs) {
      fields.push({
        header: selectedSheet[ref].w.replace('\n', ' ').replace('\r', ''),
        origin: 'xlsx',
        ref: ref.replaceAll(/\d/g, ''),
        values: []
      })
    }

    this.fields = fields
    this.selectedFields = fields.map(_ => true)

    this.fields.forEach(f =>
      this.addedHeaders.push({
        header: f.header.replace('\n', ' ').replace('\r', ''),
        origin: f.origin,
        selected: true
      })
    )

    this.matchingAddedHeaders()
  }

  private swapSelectedFields(index: number) {
    this.selectedFields = this.selectedFields.map((s, pos) => {
      return pos === index ? !s : s
    })
  }

  private swapSelectedOverwrite(index: number) {
    this.addedHeaders[index].selected = !this.addedHeaders[index].selected
  }

  private swapAllFieldsStatus(status: boolean) {
    for (const header of this.addedHeaders) {
      header.selected = status
    }
  }

  private get dataForExplanation(): any {
    return {
      tableName: this.tableName,
      originField: this.fields[this.mapFieldIndex].header,
      destField: this.headers[this.destFieldIndex]
    }
  }

  private linkXlsxFields() {
    this.fillXlsxValues()

    Store.setHasChanged(true)

    // todo: descartar fields no seleccionados

    let indexFields: number[] = []

    this.selectedFields.forEach((s, index) => {
      if (s === true) {
        indexFields.push(index)
      }
    })

    let defFields: any[] = []

    for (const i of indexFields) {
      defFields.push(this.fields[i])
    }

    this.headersWereAdded = true

    Store.setActiveHeaders([...Store.activeHeaders, ...defFields])

    this.relateRows(defFields)

    this.$toasted.success(this.$i18n.t('success.xlsxLinked') as string)
    this.close()
  }

  private fillXlsxValues() {
    const sheet = this.uploadedBook.Sheets[this.selectedSheet]

    const sheetGroups = getSheetGroups(sheet)

    const unselectedRootRefs = []
    const unselectedRootHeaders = []

    this.selectedFields.forEach((sel, index) => {
      if (!sel) {
        const ref = this.fields[index].header

        for (const key in sheet) {
          if (sheet[key].w === ref) {
            unselectedRootRefs.push(key.replaceAll(/\d/g, ''))
            unselectedRootHeaders.push(ref)
          }
        }
      }
    })

    const dontAdd = ['!ref', '!margins', '!autofilter']
    const { ref } = this.fields[this.mapFieldIndex]

    if (unselectedRootRefs.length) {
      if (unselectedRootRefs.includes(ref)) {
        unselectedRootRefs.splice(unselectedRootRefs.indexOf(ref), 1)
      }

      dontAdd.push(...unselectedRootRefs)
    }

    for (const item in sheetGroups) {
      if (!dontAdd.includes(item)) {
        const field = this.fields.find(f => f.ref === item)
        if (field) {
          for (const key in sheetGroups[item]) {
            field.values.push(sheetGroups[item][key])
          }
        }
      }
    }

    return unselectedRootRefs
  }

  private matchingAddedHeaders() {
    this.tableXlsxHeadersNames = Store.activeHeaders
      .filter(h => h.origin === 'xlsx')
      .map(h => h.header)

    this.matchingHeaders = this.tableXlsxHeadersNames.length > 0

    this.loading = false
  }

  private overwriteAndCreateXlsxFields() {
    let headers = Store.activeHeaders
    let rows = Store.activeRows

    const addedHeaders = this.addedHeaders
      .filter(a => a.selected)
      .map(a => a.header)

    const defFields = this.fields.filter(f => addedHeaders.includes(f.header))

    let defNames = defFields.map(def => def.header)

    const baseHeadersNames = headers.map(h => h.header)

    this.fillXlsxValues()

    const linkHeader = this.fields[this.destFieldIndex].header

    const linkXlsxHeaderIndex = baseHeadersNames.indexOf(linkHeader)

    const baseField = this.fields.find(f => f.header === linkHeader)

    const fillRows = (field, index) => {
      field.values.forEach((value, pos) => {
        const linkValueToFindRow = baseField.values[pos]

        const rowIndex = rows.findIndex(r => {
          return r[linkXlsxHeaderIndex] === linkValueToFindRow
        })

        if (
          index !== -1 &&
          rowIndex !== -1 &&
          rows[rowIndex][index] !== value
        ) {
          rows[rowIndex][index] = value
        }
      })

      rows.forEach(row => {
        if (!row[index]) {
          row[index] = ''
        }
      })
    }

    defNames.forEach((n: string) => {
      const field = this.fields.find(f => f.header === n)

      if (baseHeadersNames.includes(n)) {
        const index = baseHeadersNames.indexOf(n)

        fillRows(field, index)
      } else {
        Store.activeHeaders.push({
          header: n,
          origin: 'xlsx'
        })

        fillRows(field, Store.activeHeaders.length - 1)
      }
    })

    Store.setActiveRows([])
    Store.setActiveRows(rows)

    this.close()
  }

  private overwriteRows() {
    const selectedFields = this.addedHeaders.filter(head => head.selected)

    let indexHeaders: number[] = []
    // Vigilar el caso de columna coincidente y nueva
    for (const field of selectedFields) {
      indexHeaders.push(
        Store.activeHeaders.findIndex(h => {
          return h.header === field.header.replace('\n', ' ')
        })
      )
    }

    const rows = Store.activeRows

    for (const ind of indexHeaders) {
      for (const field of this.fields) {
        const { values } = field

        values.forEach((value, pos) => {
          const row = rows.find(r => {
            return r[ind] === value
          })

          const rowIndex = rows.findIndex(r => r[ind] === value)

          if (row && rowIndex !== -1) {
            this.addedHeaders.forEach(added => {
              row.push(
                this.fields.find(f => f.header === added.header).values[pos]
              )
            })

            Store.updateRow({
              row,
              rowIndex
            })
          }
        })
      }
    }
  }

  private relateRows(fields: any[]) {
    const originField = this.fields[this.mapFieldIndex].header

    const linkField = Store.activeHeaders[this.destFieldIndex].header
    const field = this.fields.find(f => f.header === originField)

    const { values } = field
    const index = Store.activeHeaders.findIndex(h => h.header === linkField)

    const rows = Store.activeRows

    if (!values) return

    values.forEach((value, pos) => {
      const row = rows.find(r => {
        return r[index] === value
      })

      const rowIndex = rows.findIndex(r => r[index] === value)
      if (row && rowIndex !== -1) {
        this.fields.forEach(added => {
          const header = fields.find(f => f.header === added.header)

          if (header) row.push(header.values[pos])
        })

        Store.updateRow({
          row,
          rowIndex
        })
      }
    })

    let maxLength = 0

    for (const row of Store.activeRows) {
      if (row.length > maxLength) {
        maxLength = row.length
      }
    }

    for (const row of Store.activeRows) {
      if (row.length < maxLength) {
        const diff = maxLength - row.length

        for (let i = 0; i < diff; i++) {
          row.push('')
        }

        Store.updateRow({
          row,
          rowIndex: Store.activeRows.indexOf(row)
        })
      }
    }

    Store.activeHeaders.splice(
      1,
      0,
      { header: 'viewerId', origin: '' },
      { header: 'externalId', origin: '' }
    )
  }

  private changeAllStatusFields(status: boolean) {
    this.selectedFields = this.selectedFields.map(_ => status)
  }

  @Watch('uploadedBook')
  onUploadedBookChange() {
    this.sheetNames = this.uploadedBook.SheetNames.map(s => {
      return {
        name: s
      }
    })

    this.selectedSheet = this.sheetNames[0].name
  }

  @Watch('selectedSheet')
  onSelectedSheetChange() {
    this.setHeaders()
  }

  @Emit() close() {}

  beforeDestroy() {
    if (this.headersWereAdded) {
      Store.activeHeaders.splice(1, 2)
    }
  }
}
