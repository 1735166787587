
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'

@Component({
  components: { Button, Input },
  directives: { onClickaway }
})
export default class CreateIntegrationModal extends Vue {
  private integrationTitle: string = ''
  private baseUrl: string = ''

  async beforeMount() {
    if (!this.integrationNames.length) {
      const {
        data: { integrationNames }
      } = await this.$apollo.query({
        query: require('@/graphql/queries/integration-names.graphql')
      })

      if (integrationNames) {
        Store.setIntegrationNames(integrationNames)
      }
    }
  }

  private get integrationNames() {
    return Store.integrationNames ? Store.integrationNames : []
  }

  private async createIntegration() {
    try {
      const integrationTitle = this.integrationTitle
      const baseUrl = this.baseUrl

      const {
        data: { createIntegration }
      } = await this.$apollo.mutate({
        variables: { integrationTitle, baseUrl },
        mutation: require('@/graphql/mutations/create-integration.graphql')
      })

      if (createIntegration) {
        Store.setIntegrationNames([
          ...this.integrationNames,
          {
            name: integrationTitle,
            id: createIntegration
          }
        ])

        this.integrationTitle = ''
        this.baseUrl = ''

        this.$toasted.success(
          this.$i18n.t('success.integrationCreated') as string
        )
      }
    } catch (error) {
      // console.error(error)
    }
  }

  @Emit() close() {}
}
