
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CumulioDashboardItem from '@/components/Dashboard/Analytics/CumulioDashboardItem.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import Store from '@/store/modules/Store'
import CumulioDashboardList from '@/components/Dashboard/Analytics/CumulioDashboardList.vue'
import Search from './Search.vue'
import ViewMenu from './ViewMenu.vue'

@Component({
  components: {
    CumulioDashboardItem,
    CumulioDashboardList,
    IconSpinner,
    Search,
    ViewMenu
  }
})
export default class Analytics extends Vue {
  @Prop() minimized!: boolean

  private dashboardSourceId: string = ''
  private dashboards = []
  private loading: boolean = true
  private disableCreate: boolean = false
  private searchTerm: any = ''
  private changeModeView: boolean = false
  private once = true

  private accessedDashboards: {
    dashboardId: string
    role: string
    shared: boolean
  }[] = []

  private ssoToken: {
    id: string
    token: string
  } = {
    id: '',
    token: ''
  }

  async created() {
    const applyToken = (token: any) => {
      this.ssoToken = token

      this.$toasted.success(this.$i18n.t('success.cumulioToken') as string)
    }

    const { token } = await this.getToken()

    if (!token) {
      const auth = await this.getAuth()
      if (!auth) {
        // Remove item localStorage & error
        localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
        this.$toasted.error(this.$i18n.t('errors.invalidToken') as string)
        this.loading = false
      } else {
        applyToken(auth.token)
      }
    } else {
      applyToken(token)
    }
  }

  private async getToken() {
    try {
      return localStorage.getItem(process.env.VUE_APP_LUZMO_COOKIE)
        ? JSON.parse(localStorage.getItem(process.env.VUE_APP_LUZMO_COOKIE))
        : await this.getAuth()
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.ssoToken') as string)
    }
  }

  async mounted() {
    const config = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )

    switch (config.analyticsList) {
      case 0:
        this.setViewMode(false)

        break
      case 1:
        this.setViewMode(true)

        break
    }
  }

  async updated() {
    if (this.once) await this.refreshDashboards()
    this.once = false
  }

  private async refreshDashboards() {
    setTimeout(async () => {
      try {
        this.loading = true

        const luzmo = this.$refs.cumulioEditor as any

        this.dashboards = await luzmo.getAccessibleDashboards()

        console.log('⭐this.dashboards⭐', this.dashboards)

        this.loading = false
      } catch {
        localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
        this.$toasted.error(this.$i18n.t('errors.ssoToken') as string)
        await this.getAuth()
      }
    }, 1000)
  }

  private async getAuth() {
    try {
      const fromAnalytics: boolean = true

      const error = () => {
        this.$toasted.error(this.$i18n.t('errors.ssoToken') as string)
        this.loading = false
        this.disableCreate = true
        localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
      }

      const {
        data: { getCumulioSsoToken }
      } = await this.$apollo.query({
        variables: { fromAnalytics },
        query: require('@/graphql/queries/get-cumulio-sso-token.graphql')
      })

      if (getCumulioSsoToken) {
        localStorage.setItem(
          process.env.VUE_APP_LUZMO_COOKIE,
          getCumulioSsoToken
        )
        return JSON.parse(getCumulioSsoToken)
      } else {
        error()
      }
    } catch (error) {
      error()
    }
  }

  private async deleteDashboard(dashboardId: string) {
    const ssoToken = JSON.stringify(this.ssoToken)

    try {
      const {
        data: { deleteDashboard }
      } = await this.$apollo.mutate({
        variables: { dashboardId, ssoToken },
        mutation: require('@/graphql/mutations/delete-dashboard.graphql')
      })

      if (deleteDashboard) {
        this.$toasted.success(this.$i18n.t('success.deleteDashboard') as string)
        await this.refreshDashboards()
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.deleteDashboard') as string)
    }
  }

  private get backToAnalytics(): boolean {
    return Store.backToAnalytics
  }

  private get filteredDashboards(): any {
    if (this.searchTerm !== '') {
      return this.dashboards.filter(d =>
        d.name.toLowerCase().startsWith(this.searchTerm.toLowerCase())
      )
    } else {
      return this.dashboards
    }
  }

  private setViewMode(mode: boolean) {
    let config = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )

    if (mode) {
      const updateConfig = {
        assetsList: config.assetsList,
        analyticsList: 1,
        mapExpanded: config.mapExpanded
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(updateConfig)
      )
    } else {
      const updateConfig = {
        assetsList: config.assetsList,
        analyticsList: 0,
        mapExpanded: config.mapExpanded
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(updateConfig)
      )
    }

    this.changeModeView = mode
  }

  private changeSearch(search: string) {
    this.searchTerm = search
  }

  @Watch('backToAnalytics')
  onbackToAnalyticsChange() {
    if (this.backToAnalytics) {
      this.dashboardSourceId = ''
      Store.setBackToAnalytics(null)
      this.refreshDashboards()
    }
  }

  @Watch('dashboardSourceId')
  async ondashboardSourceIdChange() {
    Store.setChangeAnalyticsAction(this.dashboardSourceId !== '')
  }

  beforeDestroy() {
    Store.setChangeAnalyticsAction(null)
    this.dashboardSourceId = ''
  }
}
