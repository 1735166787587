
import { Vue, Component, Emit, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'
import IconSelected from '@/assets/icons/square-check.svg'
import IconUnselected from '@/assets/icons/square.svg'
import MapFields from '@/components/shared/MapFields.vue'
import Button from '@/components/shared/Button.vue'
import IconFolder from '@/assets/icons/folder.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconXlsx from '@/assets/icons/file-excel.svg'
import { read } from 'xlsx'
import IconFileUpload from '@/assets/icons/file-upload.svg'
import getSheetGroups from '@/controllers/dataManagement/get-sheet-groups'
import IconPoint from '@/assets/icons/point.svg'

@Component({
  components: {
    IconSpinner,
    Button,
    MapFields,
    IconFileUpload,
    IconSelected,
    IconUnselected,
    IconFolder,
    IconPoint,
    IconXlsx
  },
  directives: { onClickaway }
})
export default class UpdateXlsx extends Vue {
  private activeIndex: number = null
  private dataAssets: any[] = []
  private loading: boolean = true
  private step: number = 0
  private uploadedBook: any = null
  private sheetNames: any[] = []
  private selectedSheet: string = ''
  private headersSet: Set<string> = new Set()
  private headersArr = []
  private xlsxHeaders = []
  private showHeadersArr = false
  private isDragOver: boolean = false

  mounted() {
    this.setDataAssets()
  }

  private get assets(): any[] {
    return Store.assets
  }

  private async setDataAssets() {
    for (const asset of this.assets) {
      const { bucketKey } = asset

      const {
        data: { getModels }
      } = await this.$apollo.query({
        variables: { bucketKey },
        query: require('@/graphql/queries/get-models.graphql')
      })

      this.dataAssets.push({
        assetId: asset.id,
        title: asset.title,
        models: [...getModels, ...asset.threeLeggedModels],
        rootSelected: true,
        // todo: refactor selected ??
        selected: [
          ...getModels.map(_ => true),
          ...asset.threeLeggedModels.map(_ => true)
        ]
      })
    }

    this.loading = false
  }

  private handleAsset(index: number) {
    if (this.activeIndex === index) {
      this.activeIndex = null
    } else {
      this.activeIndex = index
    }
  }

  private get checkAllSelected() {
    return this.dataAssets.some(asset => asset.rootSelected)
  }

  private get checkAllSelectedHeaders() {
    return this.xlsxHeaders.some(h => h.selected)
  }

  private upload(file: File) {
    this.loading = true

    const reader = new FileReader()

    reader.onload = ({ target: { result } }) => {
      this.uploadedBook = read(result, { type: 'binary' })
    }

    reader.readAsBinaryString(file)
  }

  private swapSelectedFields(index: number) {
    this.dataAssets[this.activeIndex].selected = this.dataAssets[
      this.activeIndex
    ].selected.map((s, pos) => {
      return pos === index ? !s : s
    })
  }

  private changeAllStatusFields(status: boolean, index: number) {
    // todo: review else
    if (index === null) {
      for (const asset of this.dataAssets) {
        asset.rootSelected = status

        if (asset.models.length) {
          asset.selected = asset.selected.map(_ => status)
        }
      }
    } else {
      if (!this.dataAssets[index].models.length) {
        this.dataAssets[index].rootSelected =
          !this.dataAssets[index].rootSelected
      } else {
        this.dataAssets[index].selected = this.dataAssets[index].selected.map(
          _ => status
        )
      }
    }
  }

  private changeAllStatusFieldsHeaders(status: boolean) {
    this.xlsxHeaders.forEach(header => (header.selected = status))
  }

  private updateStep(index: number) {
    this.step = index
  }

  private openBrowser() {
    document.getElementById('input').click()
  }

  private getAssetsFields() {
    const models = []

    for (const data of this.dataAssets) {
      data.selected.forEach((d, index) => {
        if (d) {
          models.push({ ...data.models[index], assetId: data.assetId })
        }
      })
    }

    const promises = []

    for (const model of models) {
      promises.push(this.addTableQuery(model))
    }

    Promise.all(promises).then((res: any) => {
      this.setFields(res.filter(d => d.data.getHeaders))
    })
  }

  private setFields(modelsHeaders) {
    modelsHeaders.forEach(model => {
      model.data.getHeaders
        .filter(h => h.origin === 'xlsx')
        .forEach(m => {
          this.headersSet.add(m.header)
        })

      delete model.data
      delete model.loading
      delete model.stale
      delete model.networkStatus
      delete model.__typename
    })
    this.headersArr = Array.from(this.headersSet)
  }

  private addTableQuery(model) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      const { objectId } = model

      const modelName = model.objectKey

      const assetId = model.assetId

      const tableName = 'IfcBuildingElementProxy'

      resolve({
        assetId,
        modelName: model.objectKey,
        ...(await this.$apollo.query({
          query: require('@/graphql/queries/get-headers.graphql'),
          variables: {
            objectId,
            modelName,
            assetId,
            tableName
          }
        }))
      })
    })
  }

  private getXlsxHeaders() {
    const selectedSheet = this.uploadedBook.Sheets[this.selectedSheet]

    const fields: any[] = []

    const fieldRegex = new RegExp(/[A-Z]+1$/)

    const fieldsRefs = Object.keys(selectedSheet).filter(f =>
      fieldRegex.test(f)
    )

    for (const ref of fieldsRefs) {
      const header = selectedSheet[ref].w.replace('\n', ' ').replace('\r', '')

      if (header !== 'ID BIM TABLE') {
        fields.push({
          header,
          origin: 'xlsx',
          ref: ref.replaceAll(/\d/g, ''),
          selected: true,
          values: []
        })
      }
    }

    this.xlsxHeaders = fields
  }

  private async updateData() {
    this.dataAssets.forEach(d => d.models.forEach(m => delete m.__typename))

    const update = this.dataAssets

    const sheet = this.uploadedBook.Sheets[this.selectedSheet]

    const sheetGroups = getSheetGroups(sheet)

    const xlsxData = []

    for (const key in sheetGroups) {
      const bookKey = `${key}1`

      if (sheet[bookKey]) {
        const header = sheet[bookKey].w

        xlsxData.push({
          header: this.formatHeader(header),
          values: Object.values(sheetGroups[key])
        })
      }
    }

    const selectedHeaders = this.xlsxHeaders
      .filter(h => h.selected)
      .map(h => h.header)

    try {
      const {
        data: { updateXlsxData }
      } = await this.$apollo.mutate({
        variables: { update, xlsxData, selectedHeaders },
        mutation: require('@/graphql/mutations/update-xlsx-data.graphql')
      })

      if (updateXlsxData) {
        this.$toasted.success(this.$i18n.t('updateMassiveXlsx') as string)
        this.close()
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  private formatHeader(header: string) {
    if (header.includes('\r\n')) {
      return header.replace('\r\n', ' ')
    } else if (header.includes('\r') && !header.includes('\r\n')) {
      return header.replace('\r', ' ')
    } else if (header.includes('\n') && !header.includes('\r\n')) {
      return header.replace('\n', ' ')
    } else {
      return header
    }
  }

  private handleDrop(event: DragEvent) {
    event.preventDefault()
    this.upload(event.dataTransfer.files[0])
    this.isDragOver = false
  }

  private dragEnter() {
    this.isDragOver = true
  }
  private dragLeave() {
    this.isDragOver = false
  }

  @Watch('step')
  onStepChanged() {
    if (this.step === 2 && !this.headersSet.size) {
      this.getAssetsFields()
      this.getXlsxHeaders()
    } else if (this.step === 3) {
      this.updateData()
    }
  }

  @Watch('uploadedBook')
  onUploadedBookChange() {
    this.sheetNames = this.uploadedBook.SheetNames.map(s => {
      return {
        name: s
      }
    })

    this.selectedSheet = this.sheetNames[0].name

    this.loading = false

    this.updateStep(2)
  }

  @Emit() close() {}
}
