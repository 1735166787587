import Cumulio from 'cumulio'

export default async id => {
  const client = new Cumulio({
    api_key: process.env.VUE_APP_CUMULIO_KEY,
    api_token: process.env.VUE_APP_CUMULIO_TOKEN
  })

  const data = await client.get('securable', {
    where: {
      id,
      type: 'dataset'
    },
    attributes: ['name', 'rows', 'created_at'],
    include: [
      {
        model: 'Column',
        attributes: ['name', 'type']
      }
    ]
  })

  const column = data.rows[0].columns.find(
    c => c.name.es === 'Broker State' || c.name.en === 'Broker State'
  )

  return column ? column.id : ''
}
